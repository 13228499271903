import * as React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import styled from "@emotion/styled"
import { Link } from "gatsby"

import hbg from "../../images/hbg-9.jpg"

import Chevron from "../../images/chevron.inline.svg"
import Arrow from "../../images/icon-arrow-right.inline.svg"

const PageContain = styled.div`
    min-height: 60vh;
    font-family: "BS";
`

const PageHeader = styled.div`
  
  background: url(${hbg});
  background-size: cover;
  background-position: top center;
  height: 100%;
  width: 100%;
  padding: 7rem 1rem 3rem;

  h1 {
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--white);
    letter-spacing: 0.1rem;
  }

  @media (min-width: 550px) {

    padding: 8rem 2.5rem 3rem;
  }


  @media (min-width: 768px) {

    /* padding: 10rem 6rem 4rem; */

    h1 {
      font-size: 2rem;
    }
  }

  @media (min-width: 1024px) {

    padding: 12rem 2.5rem 4rem;

    h1 {
      font-size: 2.6rem;
    }
  }
`

const PageHeaderInner = styled.div`

  @media (min-width: 1490px) {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
  }
`


const PageContent = styled.div`

  padding: 2rem 1rem 3rem;

  @media (min-width: 550px) {
    padding: 2rem 2.5rem 3rem;
  }


  @media (min-width: 768px) {
    /* padding: 4rem 2.5rem 4rem; */
  }

  @media (min-width: 1024px) {
    padding: 4rem 2.5rem 4rem;
    }
`


const PageContentInner = styled.div`

  display: flex;
  flex-direction: row;
  align-items: flex-start;

  @media (min-width: 1490px) {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
  }
`

const Sidebar = styled.div`
  margin-right: 4rem;
  margin-top: 1rem;
  width: 200px;

  @media (max-width: 900px) {
    display: none;
  }

  ul {
    li {
      margin-bottom: 1.5rem;
      padding-bottom: 1.5rem;
      border-bottom: 2px solid rgba(0,0,0,.2);
      font-weight: 500;

      a {
        display: flex;
        flex-direction: row;
        align-items: center;

        svg {
          height: 24px;
          margin-top: 1px;
          width: auto;
          margin-right: 2px;
          fill: var(--primary);
          margin-left: 0;
        }
      }
    }
  }

  @media (min-width: 1024px) {
    margin-right: 5rem;
  }

  @media (min-width: 1440px) {
    margin-right: 10rem;
  }
`

const MainContent = styled.div`
  flex: 1;

  h2 {
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.05rem;
    margin-bottom: 3rem;

    @media (min-width: 768px) {
      font-size: 2rem;
    }
  }

  h3 {
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: 0.05rem;
    margin-bottom: 1rem;
    padding-bottom: .75rem;
    border-bottom: 3px solid rgba(0,0,0,.2);
    max-width: 800px;

    @media (min-width: 768px) {
      font-size: 1.5rem;
    }

    @media (min-width: 1200px) {
      max-width: none;
    }
  }

  h4 {
    font-size: .95rem;
    font-weight: 600;
    letter-spacing: 0.01rem;
    margin-bottom: .75rem;
    display: flex;
    align-items: center;

    &:first-of-type {margin-top:2rem;}

    span {
      font-weight: 500;
    }

    svg {
      height: 12px;
      width: auto;
      /* transform: rotate(90deg); */
      margin-right: 10px;
      fill: var(--primary);
    }

    @media (min-width: 768px) {
      font-size: 1.2rem;
    }
  }

  p {
    font-size: 1rem;
    line-height: 140%;
    max-width: 800px;
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    @media (min-width: 768px) {
      font-size: 1.2rem;
    }
  }
`

const ContentBlock = styled.div`
  margin-bottom: 5rem;

  a {
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.01rem;
    margin-bottom: .75rem;
    display: flex;
    align-items: center;
  }

  svg {
    height: 12px;
    width: auto;
    /* transform: rotate(90deg); */
    margin-left: 10px;
    fill: var(--primary);
  }

  ol {
    list-style-type: decimal;
    font-size: 1rem;
    line-height: 140%;
    max-width: 800px;
    margin-bottom: 1.5rem;
    margin-left: 1.5rem;
    /* list-style-position: inside; */

    li {
      margin-bottom: .75rem;

      ol {
        margin-top: .75rem;
        list-style-type: lower-alpha;

        li {
          margin-left: 2rem;
          margin-bottom: .5rem;
        }
      }
    }

    @media (min-width: 768px) {
      font-size: 1.2rem;
    }
  }
`


const Accent = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-bottom: 4px solid var(--primary);
  width: 70px;
`

const ValuesGrid = styled.div`
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    max-width: 800px;

    @media (min-width: 900px) {
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 2rem;
    }
`

const MainContentHeading = styled.div`
  margin-bottom: 2rem;
`



const DevelopmentPage = () => (
  <Layout>
    <Seo title="Development Services" />
    <PageContain>
      <PageHeader>
        <PageHeaderInner>
          <h1>Development Services</h1>
        </PageHeaderInner>
      </PageHeader>
      <PageContent>
        <PageContentInner>
          <Sidebar>
            <ul>
              <li><Link to="/services/development" stripHash><Chevron/>Development</Link></li>
              <li><Link to="/services/construction" stripHash><Chevron/>General Construction</Link></li>
              <li><Link to="/services/subcontracting" stripHash><Chevron/>Sub Contractor</Link></li>
            </ul>
          </Sidebar>
          <MainContent>
            <MainContentHeading>
              <h2>Start With The Best</h2>
              <Accent/>
              <p>Orion has acted in many different roles including those as a developer, co-developer, project guarantor, etc.  Our development expertise means that we see projects from the Owner's points of view as much as our own.  We understand the importance of efficient Civil Design, thorough code reviews, diligent soils and existing condition investigations, etc.  Thus we offer the following services:</p>
            </MainContentHeading>
            <ContentBlock id="development">
              <ol>
                <li>
                  Construction Administration to assist the Developer with the following:
                  <ol>
                    <li>Identify, select and manage the design team.</li>
                    <li>Observe the geotechnical testing</li>
                    <li>Identify, evaluate and negotiate with General Contractors</li>
                    <li>Identify, evaluate Subcontractors and Vendors</li>
                    <li>Provide cost saving suggestions</li>
                    <li>Review the General Contractor's invoices to insure complete paperwork such as Lien releases</li>
                    <li>Review submittals</li>
                    <li>Provide field Quality Control by confirming the installed materials and methods adhere to the drawings and specifications</li>
                  </ol>
                </li>
                <li>Equity: Orion will, on select projects, invest equity on a pari parsu basis.</li>
                <li>Completion Guarantee: Orion or it's related entities will provide a completion guarantee sufficient for lenders.  Orion has provided this service on projects ranging from $16MM-$50MM in value.</li>
                <li>Joint Venture with the Developer on select projects.</li>
              </ol>
            </ContentBlock>
          </MainContent>
        </PageContentInner>
      </PageContent>
    </PageContain>
  </Layout>
)

export default DevelopmentPage
